import React from "react"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Content = styled.div`
  margin: 0 auto;
  max-width: 860px;
  padding: 1.45rem 1.0875rem;
`

const PortfolioPage = () => (
  <Layout>
    <SEO title="Portfolio" />
    <Content>
      <h3>AFR Article Reader</h3>
      <h4>NextJS App to read article content behind AFR paywall</h4>
      <a href="https://afr-reader.tim-zhang.com" target="_blank">
        <div
          style={{
            width: "100%",
            minHeight: 500,
            margin: "0 auto",
            backgroundImage: `url(${require("../../static/images/afr-article-reader.jpg")})`,
            backgroundSize: "cover",
          }}
        ></div>
      </a>
    </Content>
    <Content>
      <h3>ETF Research Platform (Working In Progress)</h3>
      <h4>NextJS App help smart investors to build their ETFs portfolio</h4>
      <a href="https://etf.tim-zhang.com" target="_blank">
        <div
          style={{
            width: "100%",
            minHeight: 500,
            margin: "0 auto",
            backgroundImage: `url(${require("../../static/images/find-your-etf.jpg")})`,
            backgroundSize: "cover",
          }}
        ></div>
      </a>
    </Content>
    <Content>
      <h3>Fund Analysis App</h3>
      <h4>Fund Analysis SPA built with ReactJS and Material UI Components!</h4>
      <a href="https://fundanalysis.tim-zhang.com" target="_blank">
        <div
          style={{
            width: "100%",
            minHeight: 500,
            margin: "0 auto",
            backgroundImage: `url(${require("../../static/images/fund-analysis.jpg")})`,
            backgroundSize: "cover",
          }}
        ></div>
      </a>
    </Content>
    <Content>
      <h3>Portfolio Scenario Analysis</h3>
      <h4>NextJS App help investors stress testing their portfolios</h4>
      <a href="https://psa.tim-zhang.com" target="_blank">
        <div
          style={{
            width: "100%",
            minHeight: 500,
            margin: "0 auto",
            backgroundImage: `url(${require("../../static/images/portfolio-scenario-analysis.jpg")})`,
            backgroundSize: "cover",
          }}
        ></div>
      </a>
    </Content>
    <Content>
      <h3>Intelligent Investor Portal</h3>
      <h4>
        Investor Portal built with ReactJS, Python, Neo4j and it's serverless!
      </h4>
      <a href="https://app.tim-zhang.com" target="_blank">
        <div
          style={{
            width: "100%",
            minHeight: 500,
            margin: "0 auto",
            backgroundImage: `url(${require("../../static/images/intelligent-investor.jpg")})`,
            backgroundSize: "cover",
          }}
        ></div>
      </a>
    </Content>
    <Content>
      <h3>Asset Allocation Monte Carlo Simulation App</h3>
      <h4>Monte Carlo Simulation App built with Amplify</h4>
      <a href="https://aamc.tim-zhang.com" target="_blank">
        <div
          style={{
            width: "100%",
            minHeight: 500,
            margin: "0 auto",
            backgroundImage: `url(${require("../../static/images/asset-allocation-monte-carlo.jpg")})`,
            backgroundSize: "cover",
          }}
        ></div>
      </a>
    </Content>
    <Content>
      <h3>Australian Wall Street</h3>
      <h4>Australian finance professionals and students society</h4>
      <a href="https://australianwallstreet.org.au" target="_blank">
        <div
          style={{
            width: "100%",
            minHeight: 500,
            margin: "0 auto",
            backgroundImage: `url(${require("../../static/images/australian-wall-street.jpg")})`,
            backgroundSize: "cover",
          }}
        ></div>
      </a>
    </Content>
    <Content>
      <h3>Asset Allocation Optimizer</h3>
      <h4>A Mean-Variance optimizer built with Python</h4>
      <a href="https://github.com/timzhangau/assetalloc" target="_blank">
        <div
          style={{
            width: "100%",
            minHeight: 500,
            margin: "0 auto",
            backgroundImage: `url(${require("../../static/images/asset-allocation-mvo.jpg")})`,
            backgroundSize: "cover",
          }}
        ></div>
      </a>
    </Content>
  </Layout>
)

export default PortfolioPage
